// Menu.js
import React, { useContext, useState, useEffect } from "react";
import Header from "./Header";
import { AuthContext } from "./AuthContext";
import "./Menu.css";

// Constants for thought status matching backend
const STATUS_MAIN = "main";
const STATUS_DONE = "done";
const STATUS_DELETED = "deleted";

const Menu = () => {
  const { authenticated, logout } = useContext(AuthContext);
  const [thought, setThought] = useState("");
  const [thoughts, setThoughts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(STATUS_MAIN); // State for active tab

  // Helper function to get auth token
  const getAuthToken = () => {
    const storedAuth = localStorage.getItem("authData");
    if (storedAuth) {
      try {
        const authData = JSON.parse(storedAuth);
        if (authData && authData.token && authData.expiration) {
          const expirationDate = new Date(authData.expiration);
          if (expirationDate > new Date()) {
            return authData.token;
          }
        }
      } catch (e) {
        console.error("Error parsing authData from localStorage:", e);
      }
    }
    logout(); // Log out if token is invalid/missing/expired
    return null;
  };

  // Fetch existing thoughts when component mounts
  useEffect(() => {
    const fetchThoughts = async () => {
      const token = getAuthToken();
      if (!token) {
        setError("Authentication required. Please log in.");
        return;
      }

      try {
        const response = await fetch("/api/thoughts", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 401) {
          setError("Session expired. Please log in again.");
          logout();
          return;
        }
        if (!response.ok) {
          throw new Error(
            `Failed to fetch thoughts (status: ${response.status})`
          );
        }
        const data = await response.json();
        // Ensure all thoughts have a status, default to 'main' if missing (for older data)
        const thoughtsWithStatus = data.map((t) => ({
          ...t,
          status: t.status || STATUS_MAIN,
        }));
        setThoughts(thoughtsWithStatus);
      } catch (err) {
        console.error("Error fetching thoughts:", err);
        setError("Failed to load thoughts. Please try again later.");
      }
    };

    if (authenticated) {
      fetchThoughts();
    }
  }, [authenticated, logout]); // Re-fetch if authentication status changes

  // Handle thought submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!thought.trim()) return;

    setIsSubmitting(true);
    setError(null);
    const token = getAuthToken();
    if (!token) {
      setError("Authentication required. Please log in.");
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch("/api/thoughts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ content: thought }),
      });

      if (response.status === 401) {
        setError("Session expired. Please log in again.");
        logout();
        return;
      }
      if (!response.ok) {
        throw new Error(
          `Failed to submit thought (status: ${response.status})`
        );
      }

      const newThought = await response.json();
      // Ensure new thought has status, default if backend doesn't send it yet
      const thoughtWithStatus = {
        ...newThought,
        status: newThought.status || STATUS_MAIN,
      };
      setThoughts([thoughtWithStatus, ...thoughts]);
      setThought("");
    } catch (err) {
      console.error("Error submitting thought:", err);
      setError("Failed to submit your thought. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle updating thought status
  const handleStatusUpdate = async (thoughtId, newStatus) => {
    const token = getAuthToken();
    if (!token) {
      setError("Authentication required. Please log in.");
      return;
    }
    setError(null); // Clear previous errors

    // Optimistically update UI
    const originalThoughts = [...thoughts];
    setThoughts(
      thoughts.map((t) =>
        t.id === thoughtId ? { ...t, status: newStatus } : t
      )
    );

    try {
      const response = await fetch(`/api/thoughts/${thoughtId}/status`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ status: newStatus }),
      });

      if (response.status === 401) {
        setError("Session expired. Please log in again.");
        logout();
        setThoughts(originalThoughts); // Revert optimistic update
        return;
      }
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({})); // Try to get error details
        throw new Error(
          `Failed to update status (status: ${response.status}). ${
            errorData.error || ""
          }`
        );
      }
      // No need to update state again if API call was successful, already done optimistically.
      // const updatedThought = await response.json();
      // setThoughts(thoughts.map(t => t.id === updatedThought.id ? updatedThought : t));
    } catch (err) {
      console.error("Error updating thought status:", err);
      setError(
        `Failed to update thought status: ${err.message}. Please refresh.`
      );
      // Revert optimistic update on error
      setThoughts(originalThoughts);
    }
  };

  // Filter thoughts based on active tab
  const filteredThoughts = thoughts.filter((t) => t.status === activeTab);

  return (
    <div>
      <Header showLogout={authenticated} showPersonalDetails={false} />
      <div className="menu-container">
        <div className="menu-content">
          <h2>Share Your Thoughts</h2>

          {error && <div className="error-message">{error}</div>}

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="thought-input" className="label">
                Enter your thought:
              </label>
              <textarea
                id="thought-input"
                value={thought}
                onChange={(e) => setThought(e.target.value)}
                className="thought-input"
                placeholder="What's on your mind?"
                disabled={isSubmitting}
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting || !thought.trim()}
              className="submit-button"
            >
              {isSubmitting ? "Submitting..." : "Submit Thought"}
            </button>
          </form>

          {/* Tabs Navigation */}
          <div className="tabs-container">
            <button
              className={`tab-button ${
                activeTab === STATUS_DONE ? "active" : ""
              }`}
              onClick={() => setActiveTab(STATUS_DONE)}
            >
              Done
            </button>
            <button
              className={`tab-button ${
                activeTab === STATUS_MAIN ? "active" : ""
              }`}
              onClick={() => setActiveTab(STATUS_MAIN)}
            >
              Main
            </button>
            <button
              className={`tab-button ${
                activeTab === STATUS_DELETED ? "active" : ""
              }`}
              onClick={() => setActiveTab(STATUS_DELETED)}
            >
              Deleted
            </button>
          </div>

          {/* Thoughts List */}
          <div className="thoughts-group">
            <h3>
              {activeTab === STATUS_MAIN && "Current Thoughts"}
              {activeTab === STATUS_DONE && "Completed Thoughts"}
              {activeTab === STATUS_DELETED && "Deleted Thoughts"}
            </h3>
            {filteredThoughts.length > 0 ? (
              <div>
                {filteredThoughts.map((t) => (
                  <div key={t.id} className="thought-item">
                    <p className="thought-text">{t.content}</p>
                    <small className="thought-timestamp">
                      {new Date(t.created_at).toLocaleString()}
                    </small>
                    <div className="thought-actions">
                      {activeTab === STATUS_MAIN && (
                        <>
                          <button
                            onClick={() =>
                              handleStatusUpdate(t.id, STATUS_DONE)
                            }
                            className="action-button done-button"
                          >
                            Done
                          </button>
                          <button
                            onClick={() =>
                              handleStatusUpdate(t.id, STATUS_DELETED)
                            }
                            className="action-button delete-button"
                          >
                            Delete
                          </button>
                        </>
                      )}
                      {activeTab === STATUS_DONE && (
                        <button
                          onClick={() => handleStatusUpdate(t.id, STATUS_MAIN)}
                          className="action-button undone-button"
                        >
                          Undone
                        </button>
                      )}
                      {activeTab === STATUS_DELETED && (
                        <button
                          onClick={() => handleStatusUpdate(t.id, STATUS_MAIN)}
                          className="action-button undelete-button"
                        >
                          Undelete
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No thoughts in this category.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
