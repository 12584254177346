import React, { useRef, useEffect, useState } from "react";
import "./Turtle.css"; // We'll create this file next for styling

const Turtle = () => {
  const canvasRef = useRef(null);
  const [command, setCommand] = useState("");
  const [history, setHistory] = useState([]); // To display command history
  const [error, setError] = useState(null); // State for error messages
  const [lines, setLines] = useState([]); // React state for line segments
  const linesRef = useRef([]); // Local ref that we can update synchronously

  // The turtle's state
  const turtleState = useRef({ x: 300, y: 200, angle: 0, penDown: true }); // Initial state

  // Get the canvas context
  const getContext = () => {
    const canvas = canvasRef.current;
    return canvas ? canvas.getContext("2d") : null;
  };

  // Clear the entire drawing area
  const clearDrawingArea = (ctx) => {
    ctx.beginPath();
    ctx.fillStyle = "#f0f0f0";
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  };

  // Draw the turtle outline
  const drawTurtle = (ctx, x, y, angle, isPenDown) => {
    const rad = (angle * Math.PI) / 180;
    const color = isPenDown ? "green" : "red";

    ctx.save();
    ctx.translate(x, y);
    ctx.rotate(rad);
    ctx.beginPath();
    ctx.moveTo(0, -10); // Tip
    ctx.lineTo(5, 5); // Bottom right
    ctx.lineTo(-5, 5); // Bottom left
    ctx.closePath();
    ctx.strokeStyle = color;
    ctx.lineWidth = 1;
    ctx.stroke();
    ctx.restore();
  };

  // Redraw entire canvas: background, lines, and the turtle
  const redraw = (ctx, allLines) => {
    clearDrawingArea(ctx);
    // Draw all lines
    for (const { startX, startY, endX, endY } of allLines) {
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.strokeStyle = "black";
      ctx.lineWidth = 2;
      ctx.stroke();
    }
    // Finally draw the turtle in its current state
    drawTurtle(
      ctx,
      turtleState.current.x,
      turtleState.current.y,
      turtleState.current.angle,
      turtleState.current.penDown
    );
  };

  // Keep linesRef in sync with lines state
  useEffect(() => {
    linesRef.current = lines;
  }, [lines]);

  // On mount, draw the initial turtle
  useEffect(() => {
    const ctx = getContext();
    if (ctx) {
      redraw(ctx, linesRef.current);
    }
  }, []);

  // Execute a command
  const executeCommand = (cmd) => {
    const ctx = getContext();
    if (!ctx) return;
    setError(null);

    const parts = cmd.toLowerCase().trim().split(" ");
    const action = parts[0];
    const value = parts.length > 1 ? parseInt(parts[1], 10) : null;

    // Check for clr/cs
    if (action === "clr" || action === "cs") {
      turtleState.current = { x: 300, y: 200, angle: 0, penDown: true };
      linesRef.current = [];
      setLines([]);
      redraw(ctx, linesRef.current);
      setHistory((prev) => [...prev, cmd]);
      return;
    }

    // Validate
    let commandIsValid = true;
    if (["fd", "bk", "rt", "lt"].includes(action)) {
      if (value === null || isNaN(value)) {
        setError(`Command '${action}' requires a numeric value.`);
        commandIsValid = false;
      }
    } else if (!["pu", "pd"].includes(action)) {
      setError(`Unknown command: ${cmd}`);
      commandIsValid = false;
    }
    if (!commandIsValid) return;

    // Current turtle state
    const {
      x: currentX,
      y: currentY,
      angle: currentAngle,
      penDown,
    } = turtleState.current;
    let newX = currentX;
    let newY = currentY;
    let newAngle = currentAngle;
    let newPenDown = penDown;

    const rad = (currentAngle * Math.PI) / 180;

    switch (action) {
      case "fd":
        newX += value * Math.sin(rad);
        newY -= value * Math.cos(rad);
        break;
      case "bk":
        newX -= value * Math.sin(rad);
        newY += value * Math.cos(rad);
        break;
      case "rt":
        newAngle = (currentAngle + value) % 360;
        break;
      case "lt":
        newAngle = (currentAngle - value + 360) % 360;
        break;
      case "pu":
        newPenDown = false;
        break;
      case "pd":
        newPenDown = true;
        break;
      default:
        break;
    }

    // If we're moving and the pen is down, store the line segment
    if ((action === "fd" || action === "bk") && penDown) {
      // Draw the line on the canvas right away
      ctx.beginPath();
      ctx.moveTo(currentX, currentY);
      ctx.lineTo(newX, newY);
      ctx.strokeStyle = "black";
      ctx.lineWidth = 2;
      // ctx.stroke();

      // Store the line in our array (to be drawn by redraw)
      const newLine = {
        startX: currentX,
        startY: currentY,
        endX: newX,
        endY: newY,
      };
      const updatedLines = [...linesRef.current, newLine];
      linesRef.current = updatedLines;
      setLines(updatedLines);
    }

    // Update the turtle state
    turtleState.current = {
      x: newX,
      y: newY,
      angle: newAngle,
      penDown: newPenDown,
    };

    // Redraw to ensure the turtle is visible at the new position
    redraw(ctx, linesRef.current);

    // Add to history
    setHistory((prev) => [...prev, cmd]);
  };

  // Event handlers
  const handleInputChange = (e) => {
    setCommand(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (command.trim()) {
      executeCommand(command);
      setCommand("");
    }
  };

  const handleSaveImage = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const link = document.createElement("a");
      link.download = "turtle_drawing.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    }
  };

  // Render UI
  return (
    <div className="turtle-container">
      <h2 className="turtle-header">Turtle Graphics</h2>

      <div className="turtle-main">
        <canvas
          ref={canvasRef}
          width="600"
          height="400"
          className="turtle-canvas"
        ></canvas>
        <div className="turtle-history">
          <h3>Command History:</h3>
          <ul>
            {history.map((histCmd, index) => (
              <li key={index}>{histCmd}</li>
            ))}
          </ul>
        </div>
      </div>

      <form onSubmit={handleFormSubmit} className="turtle-form">
        <input
          type="text"
          value={command}
          onChange={handleInputChange}
          placeholder="Enter command (e.g., fd 50, rt 90, pu, pd, clr)"
          className="turtle-input"
        />
        <button type="submit" className="turtle-button">
          Execute
        </button>
        <button type="button" onClick={handleSaveImage} className="save-button">
          Save Image
        </button>
      </form>

      {error && <div className="turtle-error">{error}</div>}

      <div className="turtle-legend">
        <h3>Commands</h3>
        <ul>
          <li>
            <code>fd N</code> - Forward N steps
          </li>
          <li>
            <code>bk N</code> - Backward N steps
          </li>
          <li>
            <code>rt N</code> - Right turn N degrees
          </li>
          <li>
            <code>lt N</code> - Left turn N degrees
          </li>
          <li>
            <code>pu</code> - Pen Up (stop drawing)
          </li>
          <li>
            <code>pd</code> - Pen Down (start drawing)
          </li>
          <li>
            <code>clr</code> - Clear screen & reset
          </li>
          <li>
            <code>cs</code> - Clear screen & reset (alias)
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Turtle;
