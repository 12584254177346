// Login.js
import React, { useState, useContext, useEffect, useRef } from "react"; // Import useRef
import { AuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com"; // Import EmailJS

const Login = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [step, setStep] = useState("username"); // 'username' or 'otp'
  const [generatedOTP, setGeneratedOTP] = useState(null);
  const [enteredOTP, setEnteredOTP] = useState("");
  const [error, setError] = useState("");
  const otpInputRef = useRef(null); // Create a ref for the OTP input

  const correctUsername = "admin"; // Change this as needed

  const handleUsernameSubmit = (e) => {
    e.preventDefault();
    if (username === correctUsername) {
      // Generate a random 6-digit OTP
      const otp = Math.floor(100000 + Math.random() * 900000).toString();
      setGeneratedOTP(otp);

      // Prepare email parameters (replace 'sacpython@gmail.com' with your email or SMS gateway address)
      const templateParams = {
        to_email: "sacpython@gmail.com",
        subject: "Your OTP Code",
        message: `${otp}`,
        from_name: "developr.dev",
        to_name: "Admin",
      };

      // Use EmailJS to send the OTP email using your public key instead of the old user_id.
      emailjs
        .send("portfolio", "default", templateParams, "YTr1ipia52GEaZyw_")
        .then(
          (response) => {
            // console.log(
            //   "OTP email sent successfully!",
            //   response.status,
            //   response.text
            // );
          },
          (err) => {
            console.error("Failed to send OTP email.", err);
          }
        );

      setStep("otp");
      setError("");
    } else {
      setError("Invalid username");
    }
  };

  const handleOTPSubmit = (e) => {
    e.preventDefault();
    if (enteredOTP === generatedOTP) {
      login();
      navigate("/menu");
    } else {
      setError("Invalid OTP");
    }
  };

  // Set a 30-second timer when on the OTP step.
  useEffect(() => {
    if (step === "otp") {
      const timer = setTimeout(() => {
        // OTP expired, reset to username step.
        setStep("username");
        setUsername("");
        setEnteredOTP("");
        setGeneratedOTP(null);
        setError("OTP expired. Please login again.");
      }, 300000); // Changed from 30 seconds to 2 minutes (120 seconds)
      // Autofocus the OTP input field when the step changes to 'otp'
      if (otpInputRef.current) {
        otpInputRef.current.focus();
      }
      return () => clearTimeout(timer);
    }
  }, [step]); // Dependency array includes step

  return (
    <div style={{ padding: "20px" }}>
      {step === "username" && (
        <form onSubmit={handleUsernameSubmit}>
          <h2>Login</h2>
          <div>
            <label>Username: </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <button type="submit">Submit</button>
        </form>
      )}
      {step === "otp" && (
        <form onSubmit={handleOTPSubmit}>
          <h2>Enter OTP</h2>
          <div>
            <label>OTP: </label>
            <input
              ref={otpInputRef} // Attach the ref here
              type="text"
              value={enteredOTP}
              onChange={(e) => setEnteredOTP(e.target.value)}
              required
            />
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <button type="submit">Verify</button>
        </form>
      )}
    </div>
  );
};

export default Login;
