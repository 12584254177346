import React, { useState, useEffect } from "react";
import "./Timer.css";

const Timer = () => {
  const [totalSessions, setTotalSessions] = useState(5);
  const [workTime, setWorkTime] = useState(35);
  const [breakTime, setBreakTime] = useState(10);
  const [longBreakTime, setLongBreakTime] = useState(0); // Default long break to 0
  const [warmUpTime, setWarmUpTime] = useState(5); // Default warm up to 5m
  const [sessionSchedule, setSessionSchedule] = useState("");
  const [totalTime, setTotalTime] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [longBreakSchedule, setLongBreakSchedule] = useState(""); // State for long break time display

  // Helper to compute slider background style
  const getSliderStyle = (value, min, max) => {
    const percentage = ((value - min) / (max - min)) * 100;
    return {
      background: `linear-gradient(90deg, #28a745 ${percentage}%, #555 ${percentage}%)`,
    };
  };

  // Generate session schedule string with warm up and conditional long break
  const generateSessionSchedule = () => {
    let schedule = "";
    const useWarmUp = warmUpTime > 0;
    const useLongBreak = longBreakTime > 0 && totalSessions > 1;
    const longBreakAfterSession = useLongBreak
      ? Math.floor(totalSessions / 2)
      : -1; // -1 indicates no long break position

    // Add warm-up if applicable
    if (useWarmUp) {
      schedule += `${warmUpTime}m Warm Up + `;
    }

    for (let i = totalSessions; i > 0; i--) {
      // Add work session
      schedule += `${workTime}m Work-${i}`;

      // Add break if not the last session
      if (i > 1) {
        const isLongBreakPosition =
          useLongBreak && i === longBreakAfterSession + 1;
        const currentBreakTime = isLongBreakPosition
          ? longBreakTime
          : breakTime;
        const breakLabel = isLongBreakPosition
          ? "Long Break"
          : `Break-${i - 1}`;
        schedule += ` + ${currentBreakTime}m ${breakLabel}`;
      }

      // Add separator if not the last iteration and not the last session
      if (i > 1) {
        schedule += " + ";
      }
    }
    // Remove trailing " + " if present
    if (schedule.endsWith(" + ")) {
      schedule = schedule.slice(0, -3);
    }
    return schedule;
  };

  // Calculate total time in hours and minutes with warm up and conditional long break
  const calculateTotalTime = () => {
    const totalWorkMinutes = workTime * totalSessions;
    let totalBreakMinutes = 0;
    const useWarmUp = warmUpTime > 0;
    const useLongBreak = longBreakTime > 0 && totalSessions > 1;

    if (totalSessions > 1) {
      if (useLongBreak) {
        const numShortBreaks = totalSessions - 2; // One less short break
        const numLongBreaks = 1;
        totalBreakMinutes =
          breakTime * numShortBreaks + longBreakTime * numLongBreaks;
      } else {
        // Original calculation without long break
        totalBreakMinutes = breakTime * (totalSessions - 1);
      }
    }

    let totalMinutes = totalWorkMinutes + totalBreakMinutes;
    if (useWarmUp) {
      totalMinutes += warmUpTime; // Add warm-up time
    }
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours}hr ${minutes}min`;
  };

  // Calculate end time with warm up and conditional long break
  const calculateEndTime = () => {
    const now = new Date();
    const totalWorkMinutes = workTime * totalSessions;
    let totalBreakMinutes = 0;
    const useWarmUp = warmUpTime > 0;
    const useLongBreak = longBreakTime > 0 && totalSessions > 1;

    if (totalSessions > 1) {
      if (useLongBreak) {
        const numShortBreaks = totalSessions - 2; // One less short break
        const numLongBreaks = 1;
        totalBreakMinutes =
          breakTime * numShortBreaks + longBreakTime * numLongBreaks;
      } else {
        // Original calculation without long break
        totalBreakMinutes = breakTime * (totalSessions - 1);
      }
    }

    let totalMinutes = totalWorkMinutes + totalBreakMinutes;
    if (useWarmUp) {
      totalMinutes += warmUpTime; // Add warm-up time
    }
    const endTimeDate = new Date(now.getTime() + totalMinutes * 60000);

    // Format as hh:mm AM/PM
    return endTimeDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  // Helper to format Date object to hh:mm AM/PM
  const formatTime = (date) => {
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  // Format current time as hh:mm AM/PM
  const formatCurrentTime = () => {
    return formatTime(new Date());
  };

  // Update all calculations and automatically copy schedule
  const updateCalculations = () => {
    const schedule = generateSessionSchedule();
    setSessionSchedule(schedule);

    const totalTimeStr = calculateTotalTime();
    setTotalTime(totalTimeStr);

    const currentTimeStr = formatCurrentTime();
    setCurrentTime(currentTimeStr);

    const endTimeStr = calculateEndTime();
    setEndTime(endTimeStr);

    // Calculate and set long break schedule if applicable
    const longBreakScheduleStr = calculateLongBreakSchedule();
    setLongBreakSchedule(longBreakScheduleStr);

    // Automatically copy the schedule to clipboard
    navigator.clipboard
      .writeText(schedule)
      .then(() => {
        showNotification("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy schedule: ", err);
        showNotification("Failed to automatically copy schedule.", true);
      });
  };

  // Function to copy schedule to clipboard, triggered by button
  const copyScheduleToClipboard = () => {
    navigator.clipboard
      .writeText(sessionSchedule) // Use state variable sessionSchedule
      .then(() => {
        showNotification("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy schedule: ", err);
        showNotification("Failed to copy schedule.", true); // Keep error notification
      });
  };

  // Calculate the start and end time of the long break
  const calculateLongBreakSchedule = () => {
    if (longBreakTime <= 0 || totalSessions <= 1) {
      return ""; // No long break if time is 0 or only 1 session
    }

    const now = new Date();
    let minutesUntilLongBreakStart = 0;
    const longBreakAfterSession = Math.floor(totalSessions / 2); // Session number (counting down) after which long break starts

    // Calculate time elapsed before the long break starts
    for (let i = totalSessions; i > longBreakAfterSession; i--) {
      minutesUntilLongBreakStart += workTime; // Add work time
      if (i > longBreakAfterSession + 1) {
        // Add short break time if it's not the break immediately before the long break
        minutesUntilLongBreakStart += breakTime;
      }
    }

    const longBreakStartTime = new Date(
      now.getTime() + minutesUntilLongBreakStart * 60000
    );
    const longBreakEndTime = new Date(
      longBreakStartTime.getTime() + longBreakTime * 60000
    );

    return `${formatTime(longBreakStartTime)} - ${formatTime(
      longBreakEndTime
    )}`;
  };

  // Show notification
  const showNotification = (message, isError = false) => {
    const notif = document.createElement("div");
    notif.className = "notification";
    notif.textContent = message;
    notif.style.position = "fixed";
    notif.style.top = "20px";
    notif.style.left = "50%";
    notif.style.transform = "translateX(-50%)";
    notif.style.zIndex = "10000";
    if (isError) {
      notif.style.backgroundColor = "#dc3545";
    }
    document.body.appendChild(notif);
    setTimeout(() => {
      notif.classList.add("fade-out");
      setTimeout(() => {
        document.body.removeChild(notif);
      }, 500);
    }, 1000);
  };

  // Initialize and set up timer when component mounts or sliders change
  useEffect(() => {
    updateCalculations();

    // Update both current time and end time every second
    const timeIntervalId = setInterval(() => {
      setCurrentTime(formatCurrentTime());
      setEndTime(calculateEndTime());
    }, 1000);

    return () => {
      clearInterval(timeIntervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSessions, workTime, breakTime, longBreakTime, warmUpTime]); // Added dependencies

  // Update URL when component mounts
  useEffect(() => {
    if (window.location.pathname !== "/timer") {
      window.history.pushState({}, "", "/timer");
    }
  }, []);

  return (
    <div>
      <div
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2 style={{ margin: 0 }}>Pomodoro Timer</h2>
        <a
          href="/"
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            marginLeft: "auto",
          }}
        >
          Back to Home
        </a>
      </div>
      <div className="timer-container">
        <p className="description">
          Customize your pomodoro timer by adjusting the sliders below.
        </p>

        <div className="properties-container">
          <div className="slider-group">
            <label htmlFor="sessions-slider">
              Total Sessions:{" "}
              <span className="slider-value">{totalSessions}</span>
            </label>
            <div className="slider-control">
              <input
                type="range"
                id="sessions-slider"
                min="1"
                max="10"
                step="1"
                value={totalSessions}
                onChange={(e) => setTotalSessions(parseInt(e.target.value))}
                className="slider"
                style={getSliderStyle(totalSessions, 1, 10)}
              />
            </div>
          </div>

          <div className="slider-group">
            <label htmlFor="work-slider">
              Work Time per Session:{" "}
              <span className="slider-value">{workTime}m</span>
            </label>
            <div className="slider-control">
              <input
                type="range"
                id="work-slider"
                min="5"
                max="60"
                step="5"
                value={workTime}
                onChange={(e) => setWorkTime(parseInt(e.target.value))}
                className="slider"
                style={getSliderStyle(workTime, 5, 60)}
              />
            </div>
          </div>

          <div className="slider-group">
            <label htmlFor="break-slider">
              Break Time per Session:{" "}
              <span className="slider-value">{breakTime}m</span>
            </label>
            <div className="slider-control">
              <input
                type="range"
                id="break-slider"
                min="5"
                max="30"
                step="5"
                value={breakTime}
                onChange={(e) => setBreakTime(parseInt(e.target.value))}
                className="slider"
                style={getSliderStyle(breakTime, 5, 30)}
              />
            </div>
          </div>

          {/* Added Long Break Slider */}
          <div className="slider-group">
            <label htmlFor="long-break-slider">
              Long Break Time:{" "}
              <span className="slider-value">{longBreakTime}m</span>
            </label>
            <div className="slider-control">
              <input
                type="range"
                id="long-break-slider"
                min="0"
                max="60"
                step="10"
                value={longBreakTime}
                onChange={(e) => setLongBreakTime(parseInt(e.target.value))}
                className="slider"
                style={getSliderStyle(longBreakTime, 0, 60)}
              />
            </div>
          </div>

          {/* Added Warm Up Slider */}
          <div className="slider-group">
            <label htmlFor="warm-up-slider">
              Warm Up Time: <span className="slider-value">{warmUpTime}m</span>
            </label>
            <div className="slider-control">
              <input
                type="range"
                id="warm-up-slider"
                min="0"
                max="60"
                step="5"
                value={warmUpTime}
                onChange={(e) => setWarmUpTime(parseInt(e.target.value))}
                className="slider"
                style={getSliderStyle(warmUpTime, 0, 60)}
              />
            </div>
          </div>
        </div>

        <div className="timer-info">
          <div className="info-item">
            <h3>Sessions</h3>
            <div className="info-value">{sessionSchedule}</div>
          </div>

          <div className="info-item">
            <h3>Total Time</h3>
            <div className="info-value">{totalTime}</div>
          </div>

          <div className="info-item">
            <h3>Current Time</h3>
            <div className="info-value">{currentTime}</div>
          </div>

          <div className="info-item">
            <h3>End Time</h3>
            <div className="info-value">{endTime}</div>
          </div>

          {/* Conditionally render Long Break Schedule */}
          {longBreakTime > 0 && totalSessions > 1 && (
            <div className="info-item">
              <h3>Long Break</h3>
              <div className="info-value">{longBreakSchedule}</div>
            </div>
          )}
        </div>
      </div>
      <div className="footer">
        <p></p>
      </div>
    </div>
  );
};

export default Timer;
